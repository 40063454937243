import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import RossHouse from '../assets/images/ross-house.jpg';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      window: {
        width: 0,
        height: 0
      }
    }
  }

  componentDidMount() {
    this.getWindowState();
    window.addEventListener('resize', this.getWindowState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowState);
  }

  getWindowState = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }

  render() {
    const {window} = this.state;

    const styles = {
      infoContainer: {
        float: 'left',
        width: window.width > 500 ? '50%' : '100%',
        marginBottom: 30
      },
      info: {
        backgroundColor: 'rgb(249, 253, 228)',
        borderStyle: 'solid',
        borderWidth: 10,
        borderColor: 'white',
        padding: 40,
      },
      image: {
        borderStyle: 'solid',
        borderWidth: 10,
        borderColor: 'white',
        width: '100%',
        height: 260,
        objectFit: 'cover',
        marginBottom: 0
      },
      mapContainer: {
        display: 'block',
        marginTop: 40
      },
      map: {
        width: '100%',
        height: 400,
        border: 'none'
      }
    }

    return(
      <Layout metadata={this.props.data.site.siteMetadata} currentPage="Contact">
        <div>
          <h1>Contact</h1>
          <div style={styles.infoContainer}>
            <div style={styles.info}>
              <strong>Opening Hours</strong><br/>
              Monday to Friday 8:15am - 9:30pm<br/>
              Saturday: Closed<br/>
              Sunday: Closed<br/>
            </div>
            <div style={styles.info}>
              <strong>Location</strong><br/>
              Ross House,
              Victoria Place,<br/>
              Eyre Square,<br/>
              Galway.
            </div>
          </div>
          <div style={styles.infoContainer}>
            <img src={RossHouse} alt="ross house" style={styles.image} />
            <div style={styles.info}>
              <strong>Call:</strong> 086 442 5401<br/>
              <strong>Email:</strong> <a href="mailto:info@mairemcandrew.com">info@mairemcandrew.com</a>
            </div>
          </div>
          <div style={styles.mapContainer}>
            <iframe 
              title="maps"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2385.9368571238424!2d-9.04898!3d53.272751!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485b96e5a146f0a5%3A0xdfa983a58768372a!2sRoss+House%2C+Merchants+Rd%2C+Galway!5e0!3m2!1sen!2sie!4v1526750850940"
              style={styles.map}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`